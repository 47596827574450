import { Menu } from "./menu.model";

// prettier-ignore
export const verticalMenuItems = [

    new Menu (1, 'Home', 'home', null, 'home', null, false, 0,"myussHomeEnabled", 'user', 'This is home. Click on this to return to home screen.'),
    new Menu (2, 'Quotes', 'quotes', null, 'quotes', null, false, 0,"myussQuotesEnabled", 'user', 'This is quotes.Create and view quotes statuses.'),
    new Menu (3, 'Orders', 'orders', null, 'orders', null, false, 0,"myussOrdersEnabled", 'user', 'This is orders. Create and view orders.'),
    new Menu (9, 'Projects', 'projects', null, 'projects', null, false, 0,"myussProjectsEnabled",'user','This is Project. Click here to view existing projects and create new.'),
    new Menu (4, 'Billing', 'billing', null, 'billing', null, false, 0,"myussBillingEnabled", 'user', 'This is billing. Pay bills for your orders.'),
    new Menu (5, 'Easy Pay', 'easypay', null, 'easy-pay', null, false, 0,"myussEasyPayEnabled",'user','This is easypay. Setup autopay and update payment method for your orders.'),
    new Menu (6, 'Admin', 'sysadmin', null, 'sysadmin', null, false, 0,"sysadmin",'sysadmin','This is System Admin. Clear cache from the system.'),
    new Menu (7, 'Cases', 'cases', null, 'cases', null, false, 0,"myussCasesEnabled",'user','This is cases. Create and view cases.'),
    new Menu (8, 'Users', 'users', null, 'users', null, false, 0,"myussUsersEnabled",'user','This is Users. Create and manage user permissions.'),

];

export const horizontalMenuItems = [];
