import { ca } from "date-fns/locale";
import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  SimpleChanges,
  ViewChild,
  effect,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { set } from "lodash";
import { Subscription, filter } from "rxjs";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { EasyPayStateService } from "src/app/features/easy-pay/services/easy-pay-state.service";
import { OrderService } from "src/app/features/orders/services/order.service";
import { ProjectService } from "src/app/features/projects/services/project.service";
import {
  EasyPayState,
  OnConfirmEasyPayRequest,
} from "src/app/models/easy-pay-model";
import { Contracts } from "src/app/models/order-model";
import { ProjectModel } from "src/app/models/project-model";
import { ConfigService } from "../../services/config/config.service";
import { MatDialog } from "@angular/material/dialog";
import { SelectProjectComponent } from "../dialogbox/select-project/select-project.component";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { ToastrService } from "ngx-toastr";
import { Platform } from "@ionic/angular";
import { caseTypes } from "../../../../assets/config/constants";
import { EnhanceQuoteService } from "src/app/features/enhance-quotes/services/enhance-quote.service";

@Component({
  selector: "app-order-lists",
  templateUrl: "./order-lists.component.html",
  styleUrls: ["./order-lists.component.scss"],
})
export class OrderListsComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;
  @Input() contractsFilter;

  public type: string = "all";
  contractsWithPaymentMethods: string;
  paymentType: string;
  endPoint: string;
  displayedColumns: string[];
  dataSource: MatTableDataSource<Contracts>;
  currentState: EasyPayState = new EasyPayState();
  allcontracts: Contracts[];
  selectedContract: Contracts[];
  contractFlag: boolean = true;
  isEasyPay: boolean = true;
  title: string;
  titleDescription: boolean = false;
  cardBrand: string;
  allStatus: string[];
  cardIcon: string;
  easyPaySubscription: Subscription;
  statusFilter = new FormControl("All");
  contracts: Contracts[];
  userId: string;
  selectedTabIndex: number;
  mobileView: boolean;
  projectId: string = "";
  projectList: ProjectModel[] = [];
  projectTypeFilter = new FormControl("All");
  projectEnabled: boolean;
  flags: Record<string, boolean | undefined>;
  quoteIds: string[] = [];
  cases: { caseType: string; caseCount: number }[];

  pageSize:number;
  currentPage:number = 0;
  paginatedRecords;
  enhanceQuoteUiFlag : boolean

  constructor(
    public easyPayService: EasyPayStateService,
    private toastr: ToastrService,
    private orderService: OrderService,
    private router: Router,
    public profileService: ProfileService,
    private _Activatedroute: ActivatedRoute,
    public _projectService: ProjectService,
    private configService: ConfigService,
    private box: MatDialog,
    public platform: Platform,
    private enhanceQuoteService: EnhanceQuoteService
  ) {
    this.projectEnabled =
      this.configService.getConfigProperty("PROJECTS_ENABLED");
    this._Activatedroute.queryParams.subscribe((params) => {
      if (params.projectId) {
        this.projectId = params.projectId;
        this.projectTypeFilter.setValue(this.projectId);
       
      }
      
    });
    effect(async () => {
      this.projectTypeFilter = new FormControl("All");
      const account = this.profileService.selectedAccount();
      this.flags = await this.profileService.getAllMyussFlags();
      if(!this.projectId){
        this._projectService.setSelectedProject(new ProjectModel());
      }

      if(account.accountId != this.userId){
        this.getContracts("Activated,Suspended,Draft");
        this.statusFilter = new FormControl("All");
        this.userId = account.accountId || "";
        if(this.flags?.myussProjectsEnabled && this.projectEnabled ){
          this.fetchProjects("Active");
          this.getProjectData();
        }
      }

      
    });
    effect(()=>{
   this.enhanceQuoteUiFlag = this.enhanceQuoteService.enhanceQuoteUiSignal()
    })
  }

  async ngOnInit(): Promise<void> {
    this.cases = caseTypes;

    this.flags = await this.profileService.getAllMyussFlags();
    
    this.cardIcon = "../../../../assets/img/card_icon/";

    // Get the current URL segments
    const urlSegments = this.router.url;

    // Check if endpoint is orders
    if (urlSegments.includes("orders")) {
      this.endPoint = "orders";
    }
    // Check if endpoint is easypay

    if (urlSegments.includes("easypay")) {
      this.endPoint = "easypay";
    }

    if (this.endPoint === "easypay") {
      this.easyPaySubscription = this.easyPayService.currentEasyPay$
        .pipe(filter((res) => res.currentStep > 0))
        .subscribe((res) => {
          this.currentState = res;
          this.paymentType = res?.paymentType;
          this.contractsWithPaymentMethods = res?.contractsWithPaymentMethods;
          this.cardBrand = res?.cardBrand ? res?.cardBrand : "";
          if (res.currentStep === 1) {
            if (this.searchInput?.nativeElement) {
              this.searchInput.nativeElement.value = "";
            }

            this.title = "Select Orders";
            this.titleDescription = false;
            if (!this.allcontracts) {
              this.getContracts("Activated,Suspended,Draft");
            } else {
              this.displayedColumns = [
                "select",
                "project",
                "name",
                "isAutoPay",
                "cardNo",
                "recurringSubtotal",
                "nextInvoiceDate",
                "shippingAddress",
              ];
              this.allcontracts = this.allcontracts.sort((a, b) =>
                b.name.localeCompare(a.name)
              );
              this.dataSource = new MatTableDataSource<Contracts>(
                this.allcontracts
              );
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.paginatedRecords = this.dataSource.filteredData.slice(0, 5);
              this.onProjectFilter();
            }
          }
          if (res.currentStep === 2) {
            this.title = "Confirm Easy Pay";
            this.titleDescription = true;
            this.displayedColumns = this.displayedColumns = [
              "project",
              "name",
              "isAutoPay",
              "cardNo",
              "recurringSubtotal",
              "nextInvoiceDate",
              "shippingAddress",
            ];
            this.selectedContract = res.contracts;
            this.dataSource = new MatTableDataSource<Contracts>(
              this.selectedContract
            );
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.paginatedRecords = this.dataSource.filteredData.slice(0, 5);
          }
        });
    }

    if (window.innerWidth <= 600) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  @HostListener("window:resize") onWindowResize(): void {
    if (window.innerWidth <= 600) {
      if (!this.mobileView) {
        this.mobileView = true;
        // this.selectedTabIndex=0;
        // this.contractsFilter = "Activated,Suspended,Draft";
      }
    } else {
      if (this.mobileView) {
        this.mobileView = false;
        // this.selectedTabIndex=0;
        // this.contractsFilter = "Activated,Suspended,Draft";
      }
    }
  }

  ngOnChanges( changes: SimpleChanges  ) {
    
    this.projectTypeFilter.setValue(this.projectId ? this.projectId : "All");
    if(!changes["contractsFilter"].isFirstChange()){
      this.getContracts(this.contractsFilter);
      if(this.flags?.myussProjectsEnabled && this.projectEnabled ){
        this.fetchProjects("Active");
        this.getProjectData();
      }
    }
  }

  getContracts(filter: string) {
    this.orderService.getAllContract(filter).subscribe((contracts) => {
      // this.contracts = contracts;
    

      if (contracts.length > 0) {
        this.contractFlag = true;
        contracts.map((contract) => {
          const GroupedArray = Object.values(contract?.caseTypeWiseCasesCount);
          contract.caseTypeWiseCasesCount = GroupedArray;
          this.contracts = contracts;
  
          return GroupedArray;
        });
       
        if (this.endPoint === "easypay") {
          this.contractFlag = true;
          this.allcontracts = contracts.filter(
            (contract: Contracts) => contract.quoteId && contract.contractNumber
          );
        
          this.displayedColumns = [
            "select",
            "project",
            "name",
            "isAutoPay",
            "cardNo",
            "recurringSubtotal",
            "nextInvoiceDate",
            "shippingAddress",
          ];
          this.allcontracts = this.allcontracts.sort((a, b) =>
            b.name.localeCompare(a.name)
          );
          this.updateDataSource(this.allcontracts);
        }
        if (this.endPoint == "orders") {
          setTimeout(() => {
            this.searchInput.nativeElement.value = "";
          }, 0);

          if (this.contractsFilter == "Activated,Suspended,Draft") {
            this.allStatus = [
              ...new Set(
                contracts.map((contract: Contracts) => contract.status)
              ),
              "In-progress",
            ] as string[];
            this.displayedColumns = [
              "name",
              "shippingAddress",
              "cardNo",
              "status",
              "openCases",
              "action",
            ];
            this.updateDataSource(contracts);
            this.onFilterSelect();
          }

          if (this.contractsFilter == "Canceled") {
            this.displayedColumns = [
              "name",
              "shippingAddress",
              "cardNo",
              "status",
              "openCases",
              "action",
            ];
            this.updateDataSource(contracts);
          }
        }
      } else {
        this.contractFlag = false;
      }

      this.onProjectFilter();
    });
  }

  getCaseTypeCount(caseArray) {
    caseTypes.forEach((itemA) => {
      let match = caseArray.find(
        (itemB) => itemB.MyUSS_Case_Type__c === itemA.caseType
      );
      if (match) {
        itemA.caseCount = match.caseCount;
      } else {
        itemA.caseCount = 0;
      }
    });

    // Output the updated listA
   
  }

  getProjectData() {
    this._projectService.projectData$.subscribe((projects) => {
      if (projects) {
        this.projectList = projects;
      }
    });
  }

  //To fetch projects based on status
  fetchProjects(status: string) {
    this._projectService.fetchProjects(status).subscribe();
  }

  selection = new SelectionModel<Contracts>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.quoteIds = [];
     

      return;
    }
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row);
          this.quoteIds.push(row.quoteId);
        });

   
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Contracts): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.contractId + 1
    }`;
  }

  // Method to handle navigation to next step
  onNext() {
   
    this.easyPayService.updateEasyPayValues({
      ...this.easyPayService.getEasyPayCurrentValue(),
      contracts: this.selection.selected.map((item) => item),
      contractIds: this.selection.selected.map((item) => item.contractId),
      currentStep: 2,
    });
  }

  // Method to handle navigation to previous step
  onBack() {
    this.easyPayService.updateEasyPayValues({
      ...this.easyPayService.getEasyPayCurrentValue(),
      currentStep: this.currentState.currentStep - 1,
    });
  }

  // Method to handle confirmation of selected contracts
  onConfirm() {
    const data: OnConfirmEasyPayRequest = {
      contractIds: this.easyPayService.getEasyPayCurrentValue().contractIds,
      paymentMethodId:
        this.easyPayService.getEasyPayCurrentValue().paymentMethodId,
    };
    this.easyPayService.updatePaymentMethod(data).subscribe((res) => {
      if (res) {
        this.router.navigate(["/easypay/promotion"]);
      }
    });
  }

  onFilterSelect() {
    this.searchInput.nativeElement.value = "";
    if (this.statusFilter.value === "All") {
      if (this.projectTypeFilter.value === "All") {
        this.dataSource = new MatTableDataSource<Contracts>(this.contracts);
        this.paginatedRecords = this.dataSource.filteredData.slice(0, 5);
      } else if (
        this.projectTypeFilter.value === "Orders with no project assign"
      ) {
        this.dataSource = new MatTableDataSource<Contracts>(
          this.contracts.filter(
            (contract) => contract.projectDetails === null && contract.quoteId
          )
        );
        this.paginatedRecords = this.dataSource.filteredData.slice(0, 5);
      } else {
        this.dataSource = new MatTableDataSource<Contracts>(
          this.contracts.filter(
            (contract) =>
              contract.projectDetails?.id === this.projectTypeFilter.value
          )
        );
        this.paginatedRecords = this.dataSource.filteredData.slice(0, 5);
      }

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      if (this.projectTypeFilter.value === "All") {
        if (this.statusFilter.value === "In-progress") {
          this.dataSource = new MatTableDataSource<Contracts>(
            this.contracts.filter(
              (contract) =>
                contract.contractNumber == "" && contract.status == "Activated"
            )
          );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.paginatedRecords = this.dataSource.filteredData.slice(0, 5);
          return;
        }
        this.dataSource = new MatTableDataSource<Contracts>(
          this.contracts.filter(
            (contract) => contract.status === this.statusFilter.value
          )
        );
      } else if (
        this.projectTypeFilter.value === "Orders with no project assign"
      ) {
        this.dataSource = new MatTableDataSource<Contracts>(
          this.contracts.filter(
            (contract) =>
              contract.status === this.statusFilter.value &&
              contract.projectDetails === null &&
              contract.quoteId
          )
        );
        this.paginatedRecords = this.dataSource.filteredData.slice(0, 5);
      } else {
        if (this.statusFilter.value === "In-progress") {
          this.dataSource = new MatTableDataSource<Contracts>(
            this.contracts.filter(
              (contract) =>
                contract.contractNumber == "" &&
                contract.projectDetails?.id === this.projectTypeFilter.value &&
                contract.status == "Activated"
            )
          );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.paginatedRecords = this.dataSource.filteredData.slice(0, 5);
          return;
        }
        this.dataSource = new MatTableDataSource<Contracts>(
          this.contracts.filter(
            (contract) =>
              contract.status === this.statusFilter.value &&
              contract.projectDetails?.id === this.projectTypeFilter.value
          )
        );
        this.paginatedRecords = this.dataSource.filteredData.slice(0, 5);
      }

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  // filter by project type
  onProjectFilter() {
    this.searchInput.nativeElement.value = "";
    this.statusFilter = new FormControl("All");
    if (this.projectTypeFilter.value === "All") {
      this.updateDisplayColumns();
      this.updateDataSource(this.contracts);
    } else if (
      this.projectTypeFilter.value === "Orders with no project assign"
    ) {
      this.selection.clear();
      this.quoteIds = [];
      this.updateDisplayColumns();
      this.updateDataSource(
        this.contracts.filter(
          (contract) => contract.projectDetails === null && contract.quoteId
        )
      );
    } else {
      this.updateDisplayColumns();
      this.updateDataSource(
        this.contracts.filter(
          (contract) =>
            contract.projectDetails?.id === this.projectTypeFilter.value
        )
      );
    }
    this.router.navigate([], {
      queryParams: {
        projectId: null,
      },
      queryParamsHandling: "merge",
    });
  }

  onVisibility(id: string) {
    this.router.navigate(["orders/ordersummary", id]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.dataSource.filterPredicate = (data: Contracts, filter: string) => {
     
   
      const dataStr = JSON.stringify(data)?.toLowerCase();
      return dataStr?.indexOf(filter?.toLowerCase()) !== -1;
    };
  }

  // Unsubscribe from subscription on component destruction
  ngOnDestroy() {
    this.easyPaySubscription?.unsubscribe();
  }

  editOrder(contractId: string) {
    if (this.profileService.selectedAccount().myussModules?.myussCasesEnabled) {
      this.router.navigateByUrl(`/orders/edit-order/${contractId}`);
    } else {
      this.router.navigateByUrl(
        `/orders/edit-order/${contractId}/change-quantity`
      );
    }
  }
  // to update the displayed columns based on the endpoint
  updateDisplayColumns() {
    if (this.endPoint === "easypay") {
      this.displayedColumns = [
        "select",
        "project",
        "name",
        "isAutoPay",
        "cardNo",
        "recurringSubtotal",
        "nextInvoiceDate",
        "shippingAddress",
      ];
    }
    if (this.endPoint === "orders") {
      if (this.projectTypeFilter.value === "Orders with no project assign") {
        this.displayedColumns = [
          "select",
          "name",
          "shippingAddress",
          "cardNo",
          "status",
          "openCases",
          "action",
        ];
      } else {
        this.displayedColumns = [
          "name",
          "shippingAddress",
          "cardNo",
          "status",
          "openCases",
          "action",
        ];
      }
    }
  }

  // to update the data source
  updateDataSource(data: Contracts[]): void {
    this.dataSource = new MatTableDataSource(data);
    this.paginatedRecords = this.dataSource.filteredData.slice(0, 5);
    this.dataSource.paginator = this.paginator;
    if(this.paginator){
      this.paginator.pageIndex = 0;
    }
    this.dataSource.sort = this.sort;
  }

  onAssignProject() {
    if (this.quoteIds.length > 0) {
      this.box
        .open(SelectProjectComponent, {
          minWidth: "30%",
          disableClose: true,
          data: { project: this.projectList, quoteIds: this.quoteIds },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            this.quoteIds = [];
            this.selection.clear();
            this.projectTypeFilter.setValue("All");
            this.getContracts("Activated,Suspended,Draft");
          }
        });
    } else {
      this.toastr.error("Please select a order to assign project");
    }
  }

  onChecked(id: string, event: MatCheckboxChange) {
    if (event.checked) {
      this.quoteIds.push(id);
    } else {
      this.quoteIds = this.quoteIds.filter((quoteId) => quoteId !== id);
    }
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.paginateData();
  }

  paginateData() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedRecords = this.dataSource.filteredData.slice(startIndex, endIndex);
  }

  // clone quote function 
  onClone(quoteId : string){
    this.enhanceQuoteService.onClone(quoteId,"order")
  }

}
