import { Injectable, signal } from "@angular/core";
import { appDisplayName } from "src/assets/config/constants";

@Injectable({
  providedIn: "root",
})
export class AppBarTitleService {
  applicationTitle = appDisplayName;
  constructor() {}

  pageTitleSignal = signal(this.applicationTitle);

  setPageTitle(newTitle: string): void {
    this.pageTitleSignal.set(newTitle);
  }
}
