import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from "src/app/app.settings";
import { Settings } from "src/app/app.settings.model";
import { EasyPayStateService } from "src/app/features/easy-pay/services/easy-pay-state.service";
import { phoneNumber } from "src/assets/config/constants";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { Platform } from "@ionic/angular";
import { appDisplayName } from "src/assets/config/constants";

@Component({
  selector: "app-restricted-access",
  templateUrl: "./restricted-access.component.html",
  styleUrls: ["./restricted-access.component.scss"],
})
export class RestrictedAccessComponent {
  @Input() imgUrl: string = "../../../../../assets/img/restricted-access3.svg";
  @Input() title: string = "Restricted Access";
  @Input() subtitle: string =
    "Account does not have self service enabled.";
  @Input() accountStatus: string | undefined = "";
  @Input() hideLogout: boolean = false;
  @Input() contactString: string = "Please contact";

  @Input() isEasyPay: boolean = false;
  @Input() showUserMenu: boolean = false;
  settings: Settings;
  phoneNumber: string;
  endPoint: string;
  applicationTitle = appDisplayName;
  constructor(
    private appSettings: AppSettings,
    private auth: AuthenticationService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    private easyPayService: EasyPayStateService,
    public profileService: ProfileService,
    private platform: Platform 
  ) {
    this.settings = this.appSettings.settings;
    this.phoneNumber = phoneNumber;
  }
  ngOnInit() {
    if(this.platform.is("hybrid") && this.platform.is("ios")){
      document.getElementById("container-wrapper")?.classList.add("top-safe");
      }
    this._Activatedroute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get("modules") === "disabled") {
        console.log("modules disabled");
        this.subtitle = `Account cannot access ${this.applicationTitle}.`;
      }
      if(window.location.href.includes('restricted-access')){
        this.showUserMenu = true;
        this.profileService.userProfile$.subscribe((profile) => {
          if(profile){
            this.router.navigate(["/home"])
          }
        })
      }
    });
    if (this.isEasyPay) {
      this.title = "You have no Active orders yet!";
      this.subtitle =
        "The selected payment method will be used for future orders.";
      this.imgUrl =
        "../../../../../assets/img/icons/Icon - No Active Orders.png";
    }
  }

  ngOnChanges() {
    // Determine specific message based on account status
    if (this.accountStatus === "CLOSED") {
      this.subtitle = "The account is closed.";
    } else if (this.accountStatus === "INACTIVE") {
      this.subtitle = "The account is inactive.";
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    });
  }

  logout() {
    this.auth.logout();
    localStorage.clear();
  }

  onBack() {
    this.easyPayService.resetEasyPayState();
  }

  onContinue() {
    this.router.navigate(["/easypay/promotion"]);
  }
}
