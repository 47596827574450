import { inject } from "@angular/core";
import { CanActivateChildFn, CanActivateFn, Router } from "@angular/router";
import { ProfileService } from "../../features/accounts/services/profile/profile-services.service";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";

export const myussEnabledGuard: CanActivateChildFn = async (route, state) => {
  const router: Router = inject(Router);
  const profileService = inject(ProfileService);
  const authService = inject(AuthenticationService);
  if (!(await authService.isAuthenticated())) {
    return false;
  }
  const myussEnabled = await profileService.getUserProfileValue("myussEnabled");
  if (myussEnabled) {
    console.log("myussEnabledGuard: myuss_enabled is true");
    return true;
  } else {
    console.log("myussEnabledGuard: myuss_enabled is false");
    router.navigate(["/restricted-access"]);
    return false;
  }
  return true
};
