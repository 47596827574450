<div *ngIf="contractFlag">
  <div class="mt-3" *ngIf="endPoint == 'easypay'">
    <h3 class="order-title">{{ title }}</h3>
    <p class="text" *ngIf="titleDescription">
      Review and confirm the below orders for Easy Pay.
    </p>
  </div>

  <div class="subheader" *ngIf="endPoint == 'easypay'">
    <div class="mt-1">
      <p fxLayoutAlign="start center">
        Selected Payment Method:
        <span
          *ngIf="paymentType == 'card'"
          class="p-1 pl-1"
          fxLayoutAlign="start center"
        >
          <img
            [src]="cardIcon + cardBrand + '.png'"
            alt=""
            *ngIf="
              [
                'jcb',
                'discover',
                'diners',
                'visa',
                'mastercard',
                'amex'
              ].includes(cardBrand)
            "
            height="20px"
            class="pl-1"
          />
          <mat-icon
            *ngIf="
              ![
                'jcb',
                'discover',
                'diners',
                'visa',
                'mastercard',
                'amex'
              ].includes(cardBrand)
            "
            class="p-1"
            >credit_card</mat-icon
          >
        </span>

        <mat-icon *ngIf="paymentType == 'us_bank_account'" class="icon_css p-1"
          >account_balance</mat-icon
        >
        **** **** **** {{ contractsWithPaymentMethods }}
      </p>
    </div>
    <div class="filter_css">
      <div class="filter px-2">
        <mat-form-field class="input_filter" appearance="outline">
          <mat-label>Search</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Search"
            #searchInput
          />
        </mat-form-field>
      </div>
      <div
        class="filter px-2"
        *ngIf="
          easyPayService.getEasyPayCurrentValue().currentStep == 1 &&
          flags?.myussProjectsEnabled &&
          projectEnabled
        "
      >
        <mat-form-field class="input_filter" appearance="outline">
          <mat-label>Project filter</mat-label>
          <mat-select
            placeholder="Ex. Active"
            (selectionChange)="onProjectFilter()"
            [formControl]="projectTypeFilter"
          >
            <mat-option [value]="'All'">All</mat-option>
            <mat-option
              *ngFor="let project of projectList"
              [value]="project.id"
              >{{ project.name }}</mat-option
            >
          </mat-select>
          <mat-icon
            matPrefix
            role="img"
            aria-label="Filter orders based on project"
            aria-hidden="false"
            ><img
              src="../../../../assets/img/icons/filter.svg"
              aria-hidden="true"
          /></mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="search_filter mt-3 w-100" *ngIf="endPoint == 'orders'">
    <div>
      <mat-form-field class="input_filter" appearance="outline">
        <mat-icon
          matPrefix
          aria-label="Search orders through order id or site address or status"
          role="img"
          aria-hidden="false"
          >search</mat-icon
        >
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
          #searchInput
        />
      </mat-form-field>
    </div>

    <div
      class="px-2"
      *ngIf="
        endPoint == 'orders' && contractsFilter == 'Activated,Suspended,Draft'
      "
    >
      <mat-form-field class="input_filter" appearance="outline">
        <mat-label>Status filter</mat-label>
        <mat-select
          placeholder="Ex. Active"
          (selectionChange)="onFilterSelect()"
          [formControl]="statusFilter"
        >
          <mat-option [value]="'All'">All</mat-option>
          <mat-option *ngFor="let status of allStatus" [value]="status">{{
            status
          }}</mat-option>
        </mat-select>
        <mat-icon
          matPrefix
          role="img"
          aria-label="Filter orders based on status"
          aria-hidden="false"
          ><img
            src="../../../../assets/img/icons/filter.svg"
            aria-hidden="true"
        /></mat-icon>
      </mat-form-field>
    </div>

    <div
      class="px-2"
      *ngIf="
        endPoint == 'orders' && flags?.myussProjectsEnabled && projectEnabled
      "
    >
      <mat-form-field class="input_filter" appearance="outline">
        <mat-label>Project filter</mat-label>
        <mat-select
          placeholder="Ex. Active"
          (selectionChange)="onProjectFilter()"
          [formControl]="projectTypeFilter"
        >
          <mat-option [value]="'All'">All</mat-option>
          <mat-option *appHideIfReadOnly [value]="'Orders with no project assign'">Orders with no project assign</mat-option>
          <mat-option *ngFor="let project of projectList" [value]="project.id">{{project.name}}</mat-option>
        </mat-select>
        <mat-icon
          matPrefix
          role="img"
          aria-label="Filter orders based on project"
          aria-hidden="false"
          ><img
            src="../../../../assets/img/icons/filter.svg"
            aria-hidden="true"
        /></mat-icon>
      </mat-form-field>
    </div>

    <div>
      <button
        class="mb-4"
        (click)="onAssignProject()"
        *ngIf="projectTypeFilter.value === 'Orders with no project assign'"
        mat-raised-button
        color="primary"
      >
        Assign to Project
      </button>
    </div>
  </div>

  <div>
    <div *ngIf="!mobileView">
      <div class="example-container" tabindex="0">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="name"
          matSortDisableClear
          matSortDirection="desc"
        >
          <!-- Checkbox Column -->
          <ng-container
            matColumnDef="select"
            *ngIf="
              endPoint == 'easypay' ||
              projectTypeFilter.value === 'Orders with no project assign'
            "
            sticky
          >
            <th mat-header-cell class="table-header" *matHeaderCellDef>
              <mat-checkbox
                color="primary"
                (change)="$event ? toggleAllRows() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="
                  $event ? selection.toggle(row) : null;
                  onChecked(row.quoteId, $event)
                "
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- project id Column -->
          <ng-container matColumnDef="project" *ngIf="endPoint == 'easypay'">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="table-header"
              mat-sort-header
            >
              PROJECT ID
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.projectDetails?.projectNumber
                  ? element.projectDetails.projectNumber
                  : "-"
              }}
            </td>
          </ng-container>

          <!-- Order id Column -->
          <ng-container matColumnDef="name">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="table-header"
              mat-sort-header
            >
              ORDER ID
            </th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <!-- isAutoPay Column -->
          <ng-container matColumnDef="isAutoPay" *ngIf="endPoint == 'easypay'">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="table-header"
              mat-sort-header
            >
              {{
                currentState.currentStep === 2
                  ? "UPDATED EASY PAY STATUS"
                  : "CURRENT EASY PAY STATUS"
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                class="p-1 div_width isAutoPayActive"
                *ngIf="currentState.currentStep === 2; else current_status"
              >
                Active
              </div>

              <ng-template #current_status>
                <div
                  class="p-1 div_width"
                  [ngClass]="{
                    isAutoPayActive: element.isAutoPay === true,
                    isAutoPayInactive: element.isAutoPay === false
                  }"
                  *ngIf="!element.easyPayDetails?.expired"
                >
                  {{ element.isAutoPay ? "Active" : "Inactive" }}
                </div>

                <div
                  class="p-1 div_width expired"
                  *ngIf="element.easyPayDetails?.expired"
                >
                  Expired
                </div>
              </ng-template>
            </td>
          </ng-container>

          <!-- easypay recurringSubtotal Column -->
          <ng-container
            matColumnDef="recurringSubtotal"
            *ngIf="endPoint == 'easypay'"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="table-header"
              mat-sort-header
            >
              RECURRING AMOUNT*
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.recurringSubtotal | currency : "USD" : "symbol" || "-"
              }}
            </td>
          </ng-container>

          <!-- cardNo Column -->
          <ng-container matColumnDef="cardNo" *ngIf="endPoint == 'easypay'">
            <th mat-header-cell *matHeaderCellDef class="table-header">
              {{
                currentState.currentStep === 2
                  ? "UPDATED PAYMENT
              METHOD"
                  : "PAYMENT METHOD"
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                fxLayoutAlign="start center"
                *ngIf="currentState.currentStep === 2; else current_payment"
              >
                <p>
                  <span
                    *ngIf="paymentType == 'card'"
                    class="p-1 pl-1"
                    fxLayoutAlign="start center"
                  >
                    <img
                      [src]="cardIcon + cardBrand + '.png'"
                      alt="Payment method card logo"
                      *ngIf="
                        [
                          'jcb',
                          'discover',
                          'diners',
                          'visa',
                          'mastercard',
                          'amex'
                        ].includes(cardBrand)
                      "
                      height="20px"
                      class="pl-1"
                    />
                    <mat-icon
                      *ngIf="
                        ![
                          'jcb',
                          'discover',
                          'diners',
                          'visa',
                          'mastercard',
                          'amex'
                        ].includes(cardBrand)
                      "
                      class="p-1"
                      >credit_card</mat-icon
                    >
                  </span>

                  <mat-icon
                    *ngIf="paymentType == 'us_bank_account'"
                    aria-label="Payment method bank logo"
                    role="img"
                    aria-hidden="false"
                    class="p-1"
                    >account_balance</mat-icon
                  >
                </p>
                <p>****</p>
                <p class="cardNo_div">
                  {{ contractsWithPaymentMethods }}
                </p>
              </div>

              <ng-template #current_payment>
                <div fxLayoutAlign="start center">
                  <p *ngIf="element.easyPayDetails?.cardNo">
                    <span
                      *ngIf="element.easyPayMode == 'card'"
                      class="p-1 pl-1"
                      fxLayoutAlign="start center"
                    >
                      <img
                        [src]="
                          cardIcon + element.easyPayDetails?.cardBrand + '.png'
                        "
                        alt="Payment method card logo"
                        *ngIf="
                          [
                            'jcb',
                            'discover',
                            'diners',
                            'visa',
                            'mastercard',
                            'amex'
                          ].includes(element.easyPayDetails?.cardBrand)
                        "
                        height="20px"
                        class="pl-1"
                      />
                      <mat-icon
                        *ngIf="
                          ![
                            'jcb',
                            'discover',
                            'diners',
                            'visa',
                            'mastercard',
                            'amex'
                          ].includes(element.easyPayDetails?.cardBrand)
                        "
                        class="p-1"
                        >credit_card</mat-icon
                      >
                    </span>

                    <mat-icon
                      *ngIf="element.easyPayMode == 'bank'"
                      aria-label="Payment method bank logo"
                      role="img"
                      aria-hidden="false"
                      class="p-1"
                      >account_balance</mat-icon
                    >
                  </p>
                  <p *ngIf="element.easyPayDetails?.cardNo">****</p>
                  <p class="cardNo_div">
                    {{
                      element.easyPayDetails?.cardNo
                        ? element.easyPayDetails?.cardNo
                        : "Manual"
                    }}
                  </p>
                </div>
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="cardNo" *ngIf="endPoint == 'orders'">
            <th mat-header-cell *matHeaderCellDef class="table-header">
              PAYMENT METHOD
            </th>
            <td mat-cell *matCellDef="let element">
              <div fxLayoutAlign="start center">
                <p *ngIf="element.easyPayDetails?.cardNo">
                  <span
                    *ngIf="element.easyPayMode == 'card'"
                    class="p-1 pl-1"
                    fxLayoutAlign="start center"
                  >
                    <img
                      [src]="
                        cardIcon + element.easyPayDetails?.cardBrand + '.png'
                      "
                      alt="Payment method card logo"
                      *ngIf="
                        [
                          'jcb',
                          'discover',
                          'diners',
                          'visa',
                          'mastercard',
                          'amex'
                        ].includes(element.easyPayDetails?.cardBrand)
                      "
                      height="20px"
                      class="pl-1"
                    />
                    <mat-icon
                      *ngIf="
                        ![
                          'jcb',
                          'discover',
                          'diners',
                          'visa',
                          'mastercard',
                          'amex'
                        ].includes(element.easyPayDetails?.cardBrand)
                      "
                      class="p-1"
                      >credit_card</mat-icon
                    >
                  </span>

                  <mat-icon
                    *ngIf="element.easyPayMode == 'bank'"
                    class="p-1"
                    aria-label="Payment method bank logo"
                    role="img"
                    aria-hidden="false"
                    >account_balance</mat-icon
                  >
                </p>
                <p *ngIf="element.easyPayDetails?.cardNo">****</p>
                <p class="cardNo_div">
                  {{
                    element.easyPayDetails?.cardNo
                      ? element.easyPayDetails?.cardNo
                      : "Manual"
                  }}
                </p>
              </div>
            </td>
          </ng-container>

          <!-- nextInvoiceDate Column -->
          <ng-container matColumnDef="nextInvoiceDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="table-header"
            >
              NEXT INVOICE DATE
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.nextInvoiceDate | date : "MM/dd/yyyy" }}
            </td>
          </ng-container>

          <!-- siteAddress Column -->
          <ng-container matColumnDef="shippingAddress">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="table-header"
            >
              SITE ADDRESS
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.shippingAddress }}
            </td>
          </ng-container>

          <!-- case count Column -->
          <ng-container matColumnDef="openCases">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="table-header"
            >
              OPEN CASES
            </th>

            <td mat-cell *matCellDef="let element">
              <button
                class="my-1 count-button"
                id="additional-services-count"
                [matMenuTriggerFor]="additionalServiceMenu"
                (click)="getCaseTypeCount(element.caseTypeWiseCasesCount)"
                [disabled]="element.casesCount === 0"
              >
                {{ element.casesCount }}
              </button>
              <mat-menu
                #additionalServiceMenu="matMenu"
                yPosition="below"
                class="menu"
              >
                <div *ngIf="element.casesCount !== 0">
                  <div class="caseTypes">
                    <img
                      src="../../../../../assets/img/ReduceQuantity.svg"
                      alt="Change Quantity image"
                    />
                    <p class="case-name">
                      {{ cases[0].caseType }}: {{ cases[0].caseCount }}
                    </p>
                  </div>
                  <div class="line"></div>
                  <div class="caseTypes">
                    <img
                      src="../../../../../assets/img/AddQuantity.svg"
                      alt="Change Quantity image"
                    />
                    <p class="case-name">
                      {{ cases[1].caseType }}: {{ cases[1].caseCount }}
                    </p>
                  </div>
                  <div class="line"></div>
                  <div class="caseTypes">
                    <img
                      src="../../../../../assets/img/AddNewUnit.svg"
                      alt="Add New Unit Type image"
                    />
                    <p class="case-name">
                      {{ cases[2].caseType }}: {{ cases[2].caseCount }}
                    </p>
                  </div>

                  <div class="line"></div>
                  <div class="caseTypes">
                    <img
                      src="../../../../../assets/img/ChangeServiceFrequency.svg"
                      alt="Change Service Frequency image"
                    />
                    <p class="case-name">
                      {{ cases[3].caseType }}: {{ cases[3].caseCount }}
                    </p>
                  </div>

                  <div class="line"></div>
                  <div class="caseTypes">
                    <img
                      src="../../../../../assets/img/Move.svg"
                      alt="Move image"
                    />
                    <p class="case-name">
                      {{ cases[4].caseType }}: {{ cases[4].caseCount }}
                    </p>
                  </div>

                  <div class="line"></div>
                </div>
              </mat-menu>
            </td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="action" *ngIf="endPoint == 'orders'">
            <th mat-header-cell *matHeaderCellDef class="table-header">
              ACTION
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="action-button pl-3">
                <button
                  class="icon-button"
                  [ngClass]="{
                    'disable-action': element.contractNumber == '',
                    'active-action': element.contractNumber
                  }"
                  [disabled]="element.contractNumber == ''"
                >
                  <img
                    src="../../../../assets/img/icons/visibility.png"
                    class="visibility_css"
                    (click)="onVisibility(element.contractId)"
                    alt=""
                  />
                </button>
                <ng-container *appHideIfReadOnly>
                  <button
                    matTooltip="Edit Order"
                    [ngClass]="{
                      'disable-action':
                        element.status != 'Activated' ||
                        element.contractNumber == '',
                      'active-action':
                        element.status === 'Activated' || element.contractNumber
                    }"
                    (click)="
                      element.status === 'Activated' &&
                        editOrder(element.contractId)
                    "
                    [disabled]="
                      element.status != 'Activated' ||
                      element.contractNumber == ''
                    "
                    class="icon-button"
                  >
                    <img
                      src="../../../../assets/img/icons/edit_square_icon.svg"
                      alt="edit icon to edit user details"
                      width="15px"
                    />

                  </button>
                </ng-container>
                <ng-container *appHideIfReadOnly  >
                  <button
                  (click)="onClone(element.quoteId)" 
                    matTooltip="Clone Order"
                    *ngIf="enhanceQuoteUiFlag"
                    class="icon-button"
                  >
                  <img src="../../../../assets/img/icons/clone-icon.svg" alt="clone icon to clone the order">


                  </button>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <!-- Status Column -->
          <ng-container matColumnDef="status" *ngIf="endPoint == 'orders'">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="table-header"
            >
              STATUS
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                *ngIf="
                  element.status == 'Activated' ||
                    element.status == 'Canceled' ||
                    element.status == 'Pending Delivery';
                  else other_Status
                "
              >
                <div
                  *ngIf="
                    element.status == 'Activated' && element.contractNumber
                  "
                  class="active_status px-3"
                >
                  {{ element.status }}
                </div>
                <div
                  *ngIf="
                    element.status == 'Activated' &&
                    element.contractNumber == ''
                  "
                  class="other_status px-3"
                >
                  In-progress
                </div>

                <div
                  *ngIf="element.status == 'Canceled'"
                  class="cancel_status px-3"
                >
                  {{ element.status }}
                </div>

                <div
                  *ngIf="element.status == 'Pending Delivery'"
                  class="other_status px-2"
                >
                  {{ element.status }}
                </div>
              </div>
              <ng-template #other_Status>
                <div class="other_status px-3">
                  {{ element.status }}
                </div>
              </ng-template>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selection.toggle(row)"
          ></tr>
          <tr class="mat-row" id="noDataTag" *matNoDataRow>
            <td class="mat-cell p-2" colspan="4">No data available.</td>
          </tr>
        </table>
      </div>
      <mat-paginator
        [pageSizeOptions]="[10, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>

    <!-- Mobile view form step 3 of easypay -->

    <div *ngIf="mobileView" class="card_size">
      <!-- select all checkbox -->
      <div *ngIf="currentState.currentStep == 1">
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
          color="primary"
        >
          Select All
        </mat-checkbox>
      </div>

      <!-- easy pay card -->
      <div class="w-100">
        <div *ngIf="dataSource?.filteredData">
          <div
            class="order_list pb-2"
            *ngFor="let contractData of paginatedRecords"
          >
            <mat-card class="custom-card card_css">
              <mat-card-header class="card_header" fxLayoutAlign="start center">
                <mat-checkbox
                  *ngIf="currentState.currentStep == 1"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(contractData) : null"
                  [checked]="selection.isSelected(contractData)"
                  [aria-label]="checkboxLabel(contractData)"
                  color="primary"
                >
                </mat-checkbox>

                <div fxFlex="auto" fxLayoutAlign="space-between center">
                  <mat-card-title class="m-0">{{
                    contractData?.projectDetails
                      ? contractData.projectDetails.projectNumber
                      : "-"
                  }}</mat-card-title>

                  <div
                    class="p-1 px-2 mr-3 isAutoPayActiveCard"
                    *ngIf="currentState.currentStep === 2; else current_status"
                  >
                    Active
                  </div>

                  <ng-template #current_status>
                    <div
                      class="p-1 px-2 mr-3"
                      [ngClass]="{
                        isAutoPayActiveCard: contractData.isAutoPay === true,
                        isAutoPayInactiveCard: contractData.isAutoPay === false
                      }"
                      *ngIf="!contractData.easyPayDetails?.expired"
                    >
                      {{ contractData.isAutoPay ? "Active" : "Inactive" }}
                    </div>

                    <div
                      class="p-1 px-2 mr-3 expiredCard"
                      *ngIf="contractData.easyPayDetails?.expired"
                    >
                      Expired
                    </div>
                  </ng-template>
                </div>
              </mat-card-header>

              <mat-card-content class="py-3">
                <table class="order_list" style="border: none">
                  <tr>
                    <td>Order Id</td>
                    <td class="site_address_css padd_css">
                      {{ contractData?.name || "-" }}
                    </td>
                  </tr>

                  <tr>
                    <td>Site Address</td>
                    <td class="site_address_css padd_css">
                      {{ contractData?.shippingAddress || "-" }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{
                        currentState.currentStep === 2
                          ? "Updated Payment Method"
                          : "Payment Method"
                      }}
                    </td>
                    <td>
                      <div
                        fxLayoutAlign="start center"
                        *ngIf="
                          currentState.currentStep === 2;
                          else current_payment
                        "
                      >
                        <p>
                          <span
                            *ngIf="paymentType == 'card'"
                            class="p-1 pl-1"
                            fxLayoutAlign="start center"
                          >
                            <img
                              [src]="cardIcon + cardBrand + '.png'"
                              alt="Payment method card logo"
                              *ngIf="
                                [
                                  'jcb',
                                  'discover',
                                  'diners',
                                  'visa',
                                  'mastercard',
                                  'amex'
                                ].includes(cardBrand)
                              "
                              height="20px"
                              class="pl-1"
                            />
                            <mat-icon
                              *ngIf="
                                ![
                                  'jcb',
                                  'discover',
                                  'diners',
                                  'visa',
                                  'mastercard',
                                  'amex'
                                ].includes(cardBrand)
                              "
                              class="p-1"
                              >credit_card</mat-icon
                            >
                          </span>

                          <mat-icon
                            *ngIf="paymentType == 'us_bank_account'"
                            aria-label="Payment method bank logo"
                            role="img"
                            aria-hidden="false"
                            class="p-1"
                            >account_balance</mat-icon
                          >
                        </p>
                        <p>****</p>
                        <p class="cardNo_div">
                          {{ contractsWithPaymentMethods }}
                        </p>
                      </div>

                      <ng-template #current_payment>
                        <div fxLayoutAlign="start center">
                          <p *ngIf="contractData.easyPayDetails?.cardNo">
                            <span
                              *ngIf="contractData.easyPayMode == 'card'"
                              class="p-1 pl-1"
                              fxLayoutAlign="start center"
                            >
                              <img
                                [src]="
                                  cardIcon +
                                  contractData.easyPayDetails?.cardBrand +
                                  '.png'
                                "
                                alt="Payment method card logo"
                                *ngIf="
                                  [
                                    'jcb',
                                    'discover',
                                    'diners',
                                    'visa',
                                    'mastercard',
                                    'amex'
                                  ].includes(
                                    contractData['easyPayDetails'].cardBrand
                                  )
                                "
                                height="20px"
                                class="pl-1"
                              />
                              <mat-icon
                                *ngIf="
                                  ![
                                    'jcb',
                                    'discover',
                                    'diners',
                                    'visa',
                                    'mastercard',
                                    'amex'
                                  ].includes(
                                    contractData['easyPayDetails'].cardBrand
                                  )
                                "
                                class="p-1"
                                >credit_card</mat-icon
                              >
                            </span>

                            <mat-icon
                              *ngIf="contractData.easyPayMode == 'bank'"
                              aria-label="Payment method bank logo"
                              role="img"
                              aria-hidden="false"
                              class="p-1"
                              >account_balance</mat-icon
                            >
                          </p>
                          <p *ngIf="contractData.easyPayDetails?.cardNo">
                            ****
                          </p>
                          <p class="cardNo_div">
                            {{
                              contractData.easyPayDetails?.cardNo
                                ? contractData.easyPayDetails?.cardNo
                                : "Manual"
                            }}
                          </p>
                        </div>
                      </ng-template>
                    </td>
                  </tr>

                  <tr>
                    <td>Next Invoice Date</td>
                    <td class="site_address_css padd_css">
                      {{ contractData?.nextInvoiceDate || "-" }}
                    </td>
                  </tr>

                  <tr>
                    <td>Recurring Amount</td>
                    <td class="site_address_css padd_css">
                      {{
                        contractData.recurringSubtotal
                          | currency : "USD" : "symbol" || "-"
                      }}
                    </td>
                  </tr>
                </table>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <mat-paginator 
        *ngIf="dataSource?.filteredData"
        [length]="dataSource?.filteredData?.length || 0"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="onPageChange($event)"
        [showFirstLastButtons]="true">
     </mat-paginator>
      </div>
    </div>
  </div>



  <div class="button_css mt-3 "*ngIf="endPoint == 'easypay'" [appFixedButton]="'73px'" [ngClass]="{'px-2':platform.is('hybrid'),}">
    <button mat-raised-button class="mr-3 px-5" (click)="onBack()" fxFlex.sm="45" fxFlex.xs="45">Back</button>
    <button mat-raised-button color="primary" class="px-5" *ngIf="currentState?.currentStep === 1" (click)="onNext()"
      [disabled]="selection.selected.length == 0" fxFlex.sm="45" fxFlex.xs="45">Next</button>
    <button mat-raised-button color="primary" class="px-5" *ngIf="currentState?.currentStep === 2" fxFlex.sm="45" fxFlex.xs="45"
      (click)="onConfirm()">Confirm</button>

  </div>

  <p
    *ngIf="
      (endPoint == 'easypay' && currentState?.currentStep === 1) ||
      currentState?.currentStep === 2
    "
    class="recurring_Note p-1 pt-3"
  >
    *Recurring amount listed here may differ from Invoices due to taxes, order
    adjustments, or other changes. The amount shown on invoices takes precedence
    and this amount is for estimating only.
  </p>

  <p
    *ngIf="endPoint == 'easypay' && currentState?.currentStep === 2"
    class="p-1 reccringMobileView"
  >
    <mat-icon color="primary" class="error_icon">error</mat-icon>
    By clicking “confirm” you agree to have United Site Services charge the
    selected payment method for the full invoice amount on the due date of the
    invoice in accordance with the terms and conditions described in your order
    documents.
  </p>
</div>

<div
  class="no_Data"
  fxLayoutAlign="center center"
  *ngIf="!contractFlag && endPoint == 'orders'"
>
  <p>No data available</p>
</div>

<div *ngIf="!contractFlag && endPoint == 'easypay'">
  <div class="mt-3">
    <h3>{{ title }}</h3>
    <p class="text" *ngIf="titleDescription">
      Review and confirm the below orders for Easy Pay.
    </p>
  </div>

  <div class="mt-1" fxLayoutAlign="start center">
    <p>
      Selected Payment Method:
      <mat-icon *ngIf="paymentType == 'card'" class="icon_css p-1"
        >credit_card</mat-icon
      >

      <mat-icon *ngIf="paymentType == 'us_bank_account'" class="icon_css p-1"
        >account_balance</mat-icon
      >
      **** **** **** {{ contractsWithPaymentMethods }}
    </p>
  </div>
  <app-restricted-access [isEasyPay]="isEasyPay"></app-restricted-access>
</div>
