<div
  mat-icon-button
  [matMenuTriggerFor]="userMenu"
  #userMenuTrigger="matMenuTrigger"
  fxLayoutAlign="center center"
  
>


<button mat-icon-button><mat-icon  class="profile-icon" color="primary" aria-label="Profile. Click to switch to another account or logout from application." aria-hidden="false">account_circle</mat-icon>
  <span class="notification-dot" *ngIf="view2Flag && myussQuoteEnabled"></span>
</button>
<span *ngIf="!isNative && !isMobile" class="userName p-1 fs-16 font-weight-500" role="menuitem" aria-label="Account's user name">{{
  profileService.selectedAccount().firstName + " " + profileService.selectedAccount().lastName
}}</span>
  <mat-icon class="userName fs-16 font-weight-500 arrow_center">keyboard_arrow_down</mat-icon>
</div>

<mat-menu
  #userMenu="matMenu"
  [overlapTrigger]="false"
  class="toolbar-dropdown-menu user-menu"
>

  <div class="account-selector">
    <div class="profile-info-wrapper py-3">
      <div class="img my-2" (click)="navigateToEdit()" >
        <span>{{getInitials()}}</span>
        <div  class="edit-icon">
          <img src="../../../assets/img/icons/edit.svg" alt="Profile Icon">
        </div>
      </div>
      <div class="profile-info">
        <h2 routerLink="account/profile" aria-label="User Firstname">
          
          {{  this.profileService.selectedAccount().firstName }}
          {{  this.profileService.selectedAccount().lastName }}
        </h2>
        <h3 class="py-1 text-center" aria-label="User lastname">
          {{  this.profileService.selectedAccount().accountName }} |
          {{  this.profileService.selectedAccount().accountNumber }}
        </h3>
        
      </div>
    </div>

    <!-- Enhance quotes ui  -->
     <!-- <ng-container *ngIf="view2Flag && myussQuoteEnabled">
       <div class="include-inactive-toggle p-1 mt-2" >
         <div style="display:flex; flex-direction:row;"> 
           <span>Switch to the new design</span>
           <div class="feature-update ml-1 pl-1 pr-1 pb-1">New</div>
         </div>
         <div>
   
           <mat-slide-toggle
             [checked]="enhanceQuoteUiFlag"
             (change)="toggleEnhanceQuotes($event.checked)"
             color="primary"
           >
           </mat-slide-toggle>
         </div>
       </div>
     </ng-container> -->
    
    <div class="accounts w-100">
       <!-- search -->
       <div class="w-100" (click)="$event.stopPropagation()">
        <mat-form-field class="px-2 error-display-none search prefix-icon-position" fxFlex="100" appearance="outline">
          <input
            matInput
            placeholder="Search account here"
            [formControl]="serchFormControl"
          />
          <mat-icon matPrefix color="primary" >search</mat-icon>
        </mat-form-field>
      </div>
    

      <div class="include-inactive-toggle p-2" (click)="$event.stopPropagation()">
        <span>Include inactive accounts</span>
        <div>

          <mat-slide-toggle
            [checked]="includeInactive"
            (change)="toggleIncludeInactive($event.checked)"
            color="primary"
          >
          </mat-slide-toggle>
        </div>
      </div>
     

      <!-- Account list  -->
      <div class="account-list w-100 px-2">
          <!-- Selected account -->
       <div class="w-100 selected-account-wrapper">
        <div class="account pl-3">
          <div class="selected-img my-2">
            <span>{{getInitials()}}</span>
          </div>
          <div class="profile-info">
            <h2>
              {{ this.profileService.selectedAccount().firstName }}
              {{ this.profileService.selectedAccount().lastName }}
            </h2>
            <h3>
              {{ this.profileService.selectedAccount().accountName }} |
              {{ this.profileService.selectedAccount().accountNumber }}
            </h3>
          </div>
        </div>
        <mat-icon class="check pr-3">check</mat-icon>
      </div>
        
        <div *ngFor="let account of accounts" class="allAccount"  (click)="accountSelectionChange(account)" >
          <div class="account pl-3">
            <div class="img my-2">
              <span>{{getInitials(account)}}</span>
            </div>
            <div class="profile-info">
              <h2>
                {{ account.firstName }}
                {{ account.lastName }}
              </h2>
              <h3>
                {{ account.accountName }} |
                {{ account.accountNumber }}
              </h3>
            </div>
          </div>
          <div class="is-account-active" [ngClass]="{
            'active-status': account.accountPaymentStatus !== 'CLOSED' && account.accountPaymentStatus !== 'INACTIVE',
            'inactive-status': account.accountPaymentStatus === 'CLOSED' || account.accountPaymentStatus === 'INACTIVE'
          }">
            {{ account.accountPaymentStatus === null ? 'Active' : account.accountPaymentStatus | titlecase}}
          </div>
        </div>
        <p *ngIf="userProfile.accounts?.length === 0 " class="p-2 text-center">Result not found</p>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <a mat-menu-item (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
    <span>Log out</span>
  </a>
</mat-menu>
