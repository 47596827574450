import { Component } from '@angular/core';
import { AccountService } from 'src/app/features/accounts/services/account/account.service';
import { ProfileService } from 'src/app/features/accounts/services/profile/profile-services.service';
import { phoneNumber } from 'src/assets/config/constants';
import { appDisplayName } from 'src/assets/config/constants';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent {
  flags: any;
  outstandingFlags: boolean = false;
  phoneNumber: string;
  applicationTitle = appDisplayName;

  constructor(private profileService: ProfileService,
    private accountService: AccountService) {
    
 } 

 async ngOnInit() {
   this.flags = await this.profileService.getAllMyussFlags()
   this.getAccountDetails()
   console.log("flag",this.flags)
   this.phoneNumber = phoneNumber;

 }
 
 

 getAccountDetails() {
  
  this.accountService.getAccountDetails(this.profileService.selectedAccount().accountId as string).then((res)=>{
    if(res.outstandingBalance != null && res.outstandingBalance > 0){
      this.outstandingFlags = true;
    }else{
      this.outstandingFlags = false;
    }
  })
 }


 
 onCall() {

  window.location.href = 'tel:' + phoneNumber;

}


}
